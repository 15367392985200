import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);
    this.state = { associations: [], loading: true };
  }

  componentDidMount() {
    this.populateAssociationData();
  }

  static renderList(associations) {
    return (

      <div className="card-deck mt-4">
        {
          associations.map(association =>
            <div key={association.id} className="card mx-auto border-0 shadow ml-1" style={{ maxWidth: "20rem" }}>
              <img className="card-img-top" src={`/${association.imageFile}`} alt={association.name} 
                style={{maxHeight: 210, overflow: "hidden", objectFit: "cover"}}></img>
              <div className="card-body">
                <div className="text-left">
                  <Link className="card-title stretched-link" to={`/list/${association.code}`}>{association.name}</Link>
                </div>
                <p className="card-text text-left mt-1">{association.description}</p>
                      <p className="card-text text-left mt-1"><small>{association.about}</small></p>
              </div>
            </div>
          )
        }
      </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Home.renderList(this.state.associations);
    return (
      <div>
        {contents}
      </div>
    );

  }
  async populateAssociationData() {
    const response = await fetch('/api/associations');
    const data = await response.json();
    this.setState({ associations: data, loading: false });
  }
}
