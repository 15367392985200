import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import './Layout.css';
import LogoImage from '../back.jpg';

const styles = {
    header: {
      backgroundImage: `url(${LogoImage})`,
      height: '100vh',
      backgroundPosition: 'fixed',
      backgroundAttachment: 'fixed'
    },
  }

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (      
      <div style={styles.header} >
        <NavMenu />
        <Container >
          {this.props.children}
        </Container>

        <Footer/>
      </div>
    );
  }
}
