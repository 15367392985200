import React, { Component } from "react";
import get from 'lodash/get';

export default class SelectFilter extends Component {

    constructor(props) {
        super(props);
        this.valueGetter = this.props.valueGetter;

        const optionValues = [...new Set(props.agGridReact.gridOptions.rowData
            .map(row => get(row, this.props.colDef.field)))].sort();

        this.state = {
            currentValue: "",
            options: optionValues
        }
    }

    isFilterActive() {
        return this.state.currentValue !== null && this.state.currentValue !== undefined && this.state.currentValue !== '';
    }

    doesFilterPass(params) {
        return this.state.currentValue === this.valueGetter(params.node);
    }

    getModel(params) {
        if (this.state.currentValue) {
            return {
                key: this.props.colDef.field,
                type: "equals",
                filter: this.state.currentValue
            }
        }
        return null;
    }

    valueChanged = (event) => {
        let newValue = event.target.value;
        if (this.state.text !== newValue) {
            this.setState({
                currentValue: newValue
            }, () => {
                this.props.filterChangedCallback();
            });

        }
    };

    render() {
        return (
            <select onChange={this.valueChanged} value={this.state.currentValue}>
                <option></option>
                {
                    this.state.options.map(optionValue =>
                        <option key={"~"+optionValue} value={optionValue}>{optionValue}</option>
                    )
                }
            </select>
        )
    }
}