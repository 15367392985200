import React, { Component, Fragment } from 'react';
import authService from './api-authorization/AuthorizeService'
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import './List.css';
import SelectFilter from './SelectFilter';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { VehiclePaint } from './VehiclePaint';

export class List extends Component {
    static displayName = List.name;

    constructor(props) {
        super(props);
        this.onGridReady = this.onGridReady.bind(this);
        this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleScrappedChanged = this.handleScrappedChanged.bind(this);
        this.state = {
            isAuth: false,
            vehicles: [],
            association: {},
            loading: true,
            quickFilterText: "",
            frameworkComponents: {
                recordTypeFilter: SelectFilter
            },
            columnDefs: [
                {
                    headerName: "Registration",
                    field: "registration",
                    cellRendererFramework: (params) => {
                        return <
                        Link
                        to = { `/vehicle/${params.data.id}` } > { params.value } < /
                        Link > 
                    },
                    tooltipValueGetter: (params) => {
                        return params.value.indexOf('*') > -1
                            ? "Login to see full registration"
                            : params.value;
                    },
                    filter: "agTextColumnFilter",
                    filterParams: {
                        suppressAndOrCondition: true
                    },
                    cellClass: function(params) { return (params.data.scrapped?'scrapped':''); }, 
                    minWidth: "80"
                },
                {
                    headerName: "First Used",
                    field: "mothEntry.firstUsedDate",
                    cellRenderer: (data) => {
                        return data.value ? (new Date(data.value)).toLocaleDateString() : '';
                    },
                    comparator: (date1, date2) => {
                        date1 = date1 ? new Date(date1) : new Date();
                        date2 = date2 ? new Date(date2) : new Date();
                        if (date1 < date2) {
                            return -1;
                        }
                        return 1;
                    },
                    maxWidth: 90
                },
                {
                    headerName: "About",
                    field: "description",
                    cellRendererFramework: (params) => {
                        return <
                        Link
                        to = { `/vehicle/${params.data.id}` } > { params.value } < /
                        Link > 
                    },
                    filter: "agTextColumnFilter",
                    filterParams: {
                        suppressAndOrCondition: true
                    },
                    cellClass: function(params) { return (params.data.scrapped?'scrapped':''); }, 
                },
                {
                    headerName: "Paint",
                    field: "paint.name",
                    filter: "recordTypeFilter",
                    cellRendererFramework: (params) => {
                        if (params.data.paint === undefined) {
                            return <
                            div></
                            div > 
                        }
                        return <
                        span
                        key = { "Paint" + params.data.id } >  < VehiclePaint
                        paint = { params.data.paint } /  >  < /
                        span > 
                    }
                },
                {
                    headerName: "LHD/RHD",
                    field: "leftHandDrive",
                    cellRenderer: (data) => {
                        return data.value? "LHD" : "RHD";
                    },
                    editable: false,
                    maxWidth: 80
                },
                {
                    headerName: "Added",
                    field: "addedUtc",
                    cellRenderer: (data) => {
                        return data.value ? (new Date(data.value)).toLocaleDateString() : '';
                    },
                    comparator: (date1, date2) => {
                        if (date1 < date2) {
                            return -1;
                        }
                        return 1;
                    },
                    maxWidth: 90
                },
                {
                    headerName: "Scrapped/Destroyed",
                    field: "scrapped",
                    cellRenderer: (data) => {
                        return data.value? "Yes" : "";
                    },
                    editable: false,
                    maxWidth: 155,
                    hide: true
                },
            ]
        };
    }
            
    dateComparator(date1, date2) {
        return date2 - date1;
    }

    componentDidMount() {
        this.populateState(this.props.match.params.associationCode);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setScrappedfilter(false);
    }

    onFirstDataRendered(params) {
        this.sizeToFit();
    }

    onFilterChanged(params) {
        var filterText = '';
        if (params.api.isAnyFilterPresent()) {
            var m = params.api.getFilterModel();
            filterText = this.state.quickFilterText;
            Object.keys(m).forEach(k => {
                filterText += `${filterText === '' ? '' : ', '}${k} ${m[k].type} ${m[k].filter}`;
            });
        }
        this.setState({
            filteredCount: params.api.getModel().rootNode.childrenAfterFilter.length,
            filterText: filterText
        }, () => {
            // then
        });
    }

    sizeToFit() {
        this.gridApi.sizeColumnsToFit();
    }

    autoSizeAll(skipHeader) {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    handleSearchChange(event) {
        this.gridApi.setQuickFilter(event.target.value);
        this.setState({
            quickFilterText: event.target.value
        });
    }
                    
    handleScrappedChanged(event) {
        const target = event.target;
        var value = target.checked;
        this.setScrappedfilter(value);
    }
                        
    setScrappedfilter(value) {
        var filterComponent = this.gridApi.getFilterInstance("scrapped");
        if (value) {
            filterComponent.setModel(null);
        } else {
            filterComponent.setModel({
                type: "equals",
                filter: value
            });
        }
        this.gridApi.onFilterChanged();
    }
                                                                                                          
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : List.renderList(this);
        return (
            <div>
                {contents}
            </div>
        );
    }

    static renderList(self) {
        let canAdd = self.state.isAuth;
        let addButton = canAdd
            ? <Link to={`/add/${self.state.association.code}`} className="btn btn-outline-primary">Add a new {self.state.association.name}</Link>
            : <div></div>;
        return (
            <div>
                <div className="row">
                    <div className="col">
                        <span className="float-right text-right">{List.renderTip(self)}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                            <h3>
                                <span>{self.state.vehicles ? self.state.vehicles.length : 0}</span>
                                <span> </span>
                                <span>{self.state.association.description}</span>
                            </h3>
                    </div>
                    <div className="col">
                        <input className="form-control float-right" type="text" value={self.state.searchTest} name="searchText"
                                onChange={self.handleSearchChange} id="searchText" placeholder="Search..."></input> 
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="ag-theme-balham mt-2" style={{ height: '50vh', width: '100%' }}>
                            <AgGridReact
                                enableCellTextSelection={true}
                                reactNext={true}
                                columnDefs={self.state.columnDefs}
                                rowData={self.state.vehicles}
                                suppressCellSelection="true"
                                frameworkComponents={self.state.frameworkComponents}
                                suppressMenuHide="true"
                                defaultColDef={{
                                    resizable: true,
                                    sortable: true,
                                    tooltipValueGetter: (params) => params.value
                                }}
                                onGridReady={self.onGridReady}
                                onFirstDataRendered={self.onFirstDataRendered}
                                onFilterChanged={self.onFilterChanged}
                                alwaysShowVerticalScroll="true" 
                            >
                            </AgGridReact>                            
                        </div>
                    </div>
                </div>
                    <div className="row mt-1">
                        <div className='col'>
                            <span className="small">
                                {self.state.filteredCount}
                                {self.gridApi && self.gridApi.isAnyFilterPresent() ? ' matching.. ' + self.state.filterText : ' total'}
                            </span>
                        </div>
                        <div className='col'>
                            <div className="form-check float-right">
                                <input onChange={self.handleScrappedChanged} type="checkbox" className="form-check-input" id="scrappedInput" />
                                <label className="form-check-label" htmlFor="scrappedInput">Include scrapped/destroyed</label>
                            </div>
                        </div>
                    </div>                
                <div className="row mt-3 mb-3" >
                    <div className="col">
                        {addButton}
                    </div>
                </div>
                
                    <div className="row">
                    <div className="col">
                        <span>
                            <span>Lowst recorded odometer; </span>
                            {self.state.stats &&
                                <Fragment>
                                    <Link to={`/vehicle/${self.state.stats.odometerLeastVehicle.id}`}>
                                        {self.state.stats.odometerLeastVehicle.registration}</Link>
                                    <span className="ml-1">{parseInt(self.state.stats.odometerLeast).toLocaleString()}</span>
                                </Fragment>
                            }
                        </span>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col">
                        <span>
                            <span>Highest recorded odometer; </span>
                            {self.state.stats &&
                                <Fragment>
                                    <span className="ml-1">{parseInt(self.state.stats.odometerMost).toLocaleString()}</span>
                                </Fragment>
                            }
                        </span>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col">
                        <span>
                            Average recorded odometer;
        {self.state.stats &&
                                <Fragment>
                                    <span className="ml-1">{parseInt(self.state.stats.odometerAverage).toLocaleString()}</span>
                                </Fragment>
                            }
                        </span>
                    </div>
                    </div>

                </div>
            
        );
    }

    static renderTip(self) {
        return <div></div>
        // if (self.state.isAuth) {
        //     return <div></div>
        // }
        // return (
        //     <span className="">
        //         <Link to={`/authentication/login`}>Login</Link> for more details
        //     </span>
        // );
    }

    async populateState(associationCode) {
        var isAuth = await authService.isAuthenticated();
        const token = isAuth
            ? await authService.getAccessToken()
            : null;
        const listResponse = await fetch(
            `/api/vehicles?associationCode=${associationCode}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const associationResponse = await fetch(
            `/api/associations/code/${associationCode}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const associationStatsResponse = await fetch(
            `/api/associations/stats/code/${associationCode}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });


        var vehicles = [];
        //const vehicles = await listResponse.json();
        const contentType = listResponse.headers.get("content-type")
        if (contentType && contentType.indexOf("application/json") !== -1) {
            vehicles = await listResponse.json();
        } else {
            try {
                console.log(listResponse.text());
            }
            catch(err) {
                console.log(err);
            }
        }
        const assocation = await associationResponse.json();

        this.setState({
            isAuth: isAuth,
            vehicles: vehicles.sort((a, b) => a.registration.localeCompare(b.registration)),
            filteredCount: vehicles.length, 
            association: assocation,
            loading: false
        }, () => {
            // then
        });

        const statsContentType = associationStatsResponse.headers.get("content-type")
        if (statsContentType && statsContentType.indexOf("application/json") !== -1) {
            var stats = await associationStatsResponse.json();
            this.setState({
                stats: stats
            }, () => {
                // then
            });
        } else {
            try {
                console.log(associationStatsResponse.text());
            }
            catch(err) {
                console.log(err);
            }            
        }
    }
}
