import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export class VehicleHead extends Component {
    static displayName = VehicleHead.name;

    render() {
        const variant = this.props.vehicle.variant || { name: "", model: { name: "", make: { name: "" } } }
        let lin = this.props.vehicle && this.props.vehicle.association
            ? <Link className="ml-1 float-right text-right" to={`/list/${this.props.vehicle.association.code}`}>
                {this.props.vehicle.association.description}
            </Link>
            : <div></div>;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-8">
                        <h3>
                            <span><strong>{this.props.vehicle.registration}</strong> </span>
                            <span>{variant.model.make.name} </span>
                            <span>{variant.model.name} </span>
                            <span>{variant.name}</span>
                        </h3>
                    </div>
                    <div className="col">
                        {lin}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">

                    </div>
                </div>
            </Fragment>
        );
    }
}
