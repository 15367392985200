import React, { Component, Fragment } from 'react';
//import moment from 'moment';

export class History extends Component {
    static displayName = History.name;

    constructor(props) {
        super(props);
        this.state = {
            motTests: []
        }
    }

    componentDidMount() {

    }

    static getDerivedStateFromProps(props, current_state) {
        if (props.mothEntry && props.mothEntry.motTests) {
            var motTests = props.mothEntry.motTests
                .sort((a, b) => (a.completedDate > b.completedDate) ? -1 : ((b.completedDate > a.completedDate) ? 1 : 0))
                .slice();
            return {
                motTests: motTests
            }
        }
        return current_state;
    }

    render() {
        var seq = 0;
        return (
            <Fragment>
                {this.props.mothEntry &&
                    <Fragment>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>Make</td>
                                    <td>{this.props.mothEntry.make}</td>
                                </tr>
                                <tr>
                                    <td>Model </td>
                                    <td>{this.props.mothEntry.model}</td>
                                </tr>
                                <tr>
                                    <td>First Used </td>
                                    <td>{this.props.mothEntry.firstUsedDate}</td>
                                </tr>
                                <tr>
                                    <td>Fuel Type </td>
                                    <td>{this.props.mothEntry.fuelType}</td>
                                </tr>
                                <tr>
                                    <td>Primary Colour </td>
                                    <td>{this.props.mothEntry.primaryColour}</td>
                                </tr>
                                {this.state.motTests &&
                                    <Fragment>
                                        {this.state.motTests.map(motTest =>
                                            <tr key={seq++}>
                                                <td>{motTest.completedDate} </td>
                                                <td>{parseInt(motTest.odometerValue).toLocaleString()} {motTest.odometerUnit}</td>
                                            </tr>
                                        )}
                                    </Fragment>
                                }
                            </tbody>
                        </table>

                    </Fragment>
                }
            </Fragment>
        );
    }
}
