import React, { Component, Fragment } from 'react';
import authService from './api-authorization/AuthorizeService'
import { VehicleHead } from './VehicleHead';
import { History } from './History';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import responseMessage from './ResponseMessage';

export class VehicleEdit extends Component {
    static displayName = VehicleEdit.name;

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            readonly: false,
            activeTab: '1',
            vehicle: {
                registration: "",
                leftHandDrive: false, 
                scrapped: false
            },
            paints: []
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    componentDidMount() {
        if (this.props.match.path.startsWith("/vehicle")) {
            this.setState({ readonly: true });
        }
        if (this.props.match.params.id) {
            this.populateState(this.props.match.params.id);
        }
        else if (this.props.match.params.associationCode) {
            this.populateState(null, this.props.match.params.associationCode);
        }
    }

    handleInputChange(event) { 
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        
        const name = target.name;
        if (name.endsWith("Id") || name === 'odometerAdd') {
            value = parseInt(value);
        }
        const v = this.state.vehicle;
        v[name] = value;
        this.setState({
            vehicle: v
        });
    }

    handleSubmit(event) {
        const isEdit = this.state.vehicle.id && !this.state.readonly;
        const url = isEdit ? `/api/vehicles/${this.state.vehicle.id}` : '/api/vehicles';
        const method = isEdit ? "PUT" : "POST";
        const body = JSON.stringify(this.state.vehicle);
        fetch(
            url, {
            method: method,
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Content-Type': 'application/json'
            },
            body: body
        }
        ).then((response) => {
            if (!response.ok) {
                responseMessage.show(response);
            }
            else return isEdit ? {} : response.json();
        }).then((responseJson) => {
            this.setState({ readonly: true });
        });
        event.preventDefault();
    }

    handleDelete() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div>
                        <h1 style={{ textAlign: 'center' }}>Delete {this.state.vehicle.registration}</h1>
                        <p style={{ textAlign: 'center' }}>Are you sure you want remove {this.state.vehicle.registration}?</p>
                        <button className="btn btn-outline-primary ml-3" onClick={onClose}>Oops, No!</button>
                        <button className="btn btn-danger float-right mr-3" onClick={() => {
                            fetch(
                                `/api/vehicles/${this.state.vehicle.id}`, {
                                method: "DELETE",
                                headers: {
                                    'Authorization': `Bearer ${this.state.token}`
                                }
                            }).then((response) => {
                                if (!response.ok) {
                                    responseMessage.show(response);
                                }
                                else {
                                    onClose();
                                    this.props.history.goBack();
                                }
                            });
                        }}
                        >Yes, Delete</button>
                    </div>
                );
            }
        });
    }

    static renderDetail(self) {
        let canEdit = self.state.readonly && self.state.user;
        return (
            <Fragment>
                <VehicleHead vehicle={self.state.vehicle} />

                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: self.state.activeTab === '1' })}
                            onClick={() => { self.toggle('1'); }}>
                            Details
                    </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: self.state.activeTab === '2' })}
                            onClick={() => { self.toggle('2'); }}>
                            History
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={self.state.activeTab}>
                    <TabPane tabId="1">
 
                                <form onSubmit={self.handleSubmit}>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <fieldset disabled={self.state.readonly ? true : false}>

                                                <div className="form-group">
                                                    <label htmlFor="inputRegistration">Registration</label>
                                                    <input type="text" 
                                                        value={self.state.vehicle.registration} 
                                                        name="registration" onChange={self.handleInputChange} 
                                                        className="form-control" 
                                                        id="inputRegistration"></input>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="descriptionTextarea">About</label>
                                                    <textarea value={self.state.vehicle.description} 
                                                        name="description" onChange={self.handleInputChange} 
                                                        className="form-control" 
                                                        id="descriptionTextarea" 
                                                        rows="3"></textarea>
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label htmlFor="paintSelect">Paint</label>
                                                            <select className="form-control" 
                                                                id="paintSelect" name="paintId"
                                                                value={self.state.vehicle.paintId} 
                                                                onChange={self.handleInputChange}>
                                                                <option value={null}></option>
                                                                {
                                                                    self.state.paints.map(paint =>
                                                                        <option key={paint.id} 
                                                                            value={parseInt(paint.id)}>{paint.name} {paint.code}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label htmlFor="secondPaintSelect">Secondary Paint</label>
                                                            <select className="form-control" 
                                                                id="secondPaintSelect" 
                                                                name="secondaryPaintId" 
                                                                value={self.state.vehicle.secondaryPaintId} 
                                                                onChange={self.handleInputChange}>
                                                                <option value={null}></option>
                                                                {
                                                                    self.state.paints.map(paint =>
                                                                        <option key={"2nd"+paint.id} 
                                                                        value={parseInt(paint.id)}>{paint.name} {paint.code}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                                <div className="form-group">
                                                    <label htmlFor="odoSelect">Odometer Add</label>
                                                    <select className="form-control" 
                                                        id="odoSelect" 
                                                        name="odometerAdd" 
                                                        value={self.state.vehicle.odometerAdd} 
                                                        onChange={self.handleInputChange}>
                                                        <option value='0'>0</option>
                                                        <option value='100000'>100,000</option>
                                                    </select>
                                                </div>



                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input className="form-check-input"
                                                                    name="leftHandDrive"
                                                                    type="checkbox"
                                                                    checked={self.state.vehicle.leftHandDrive}
                                                                    onChange={self.handleInputChange} />
                                                                Left hand drive
                                                            </label>
                                                        </div>
                          
                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input className="form-check-input"
                                                                    name="scrapped"
                                                                    type="checkbox"
                                                                    checked={self.state.vehicle.scrapped}
                                                                    onChange={self.handleInputChange} />
                                                                Scrapped/destroyed
                                                            </label>
                                                        </div>
                                            
                                         

                                            </fieldset>

                                        </div>
                                    </div>
                                    <div className="row mt-3 mb-3">
                                        <div className="col">
                                            <div>
                                                <button onClick={() => {
                                                    if (self.state.vehicle.id && !self.state.readonly) {
                                                        self.populateVechicle(self.state.vehicle.id);
                                                        self.setState({ readonly: true });
                                                    }
                                                    else {
                                                        self.props.history.goBack();
                                                    }
                                                }} type="button" className="btn btn-outline-primary">{self.state.readonly ? 'Go Back' : 'Cancel'}</button>
                                                {!self.state.readonly > 0 &&
                                                    <Fragment>
                                                        <input type="submit" value="Save" className="btn btn-outline-warning ml-1" />
                                                    </Fragment>
                                                }
                                                {canEdit &&
                                                    <Fragment>
                                                        {/* <Link to={`/edit/${self.state.vehicle.id}`} className="btn btn-outline-warning ml-1" >Edit</Link> */}
                                                        <button type="button" onClick={() => self.setState({ readonly: false })} className="btn btn-outline-warning ml-1" >Edit</button>
                                                        <button type="button" onClick={() => self.handleDelete()} className="btn btn-outline-danger float-right">Delete</button>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                
                    </TabPane>

                    <TabPane tabId="2">

                                <History mothEntry={self.state.vehicle.mothEntry}></History>

                    </TabPane>

                </TabContent>

            </Fragment>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : VehicleEdit.renderDetail(this);
        return (
            <Fragment>
                {contents}
            </Fragment>
        );
    }

    async populatePaints(variantId) {
        const paintResponse = await fetch(
            `/api/paints/variant/${variantId}`);
        const paintContentType = paintResponse.headers.get("content-type")
        if (paintContentType && paintContentType.indexOf("application/json") !== -1) {
            const paints = await paintResponse.json();
            this.setState({
                paints: paints,
            }, () => {
                // then
            });
        }
        else {
            try {
                console.log(paintResponse.text());
            }
            catch(err) {
                console.log(err);
            }
        }
    }

    async populateState(id, associationCode) {
        const user = await authService.getUser();
        const token = await authService.getAccessToken();
        this.setState({ user: user, token: token });

        if (id) {
            await this.populateVechicle(id);
        }
        else if (associationCode) {
            const associationResponse = await fetch(
                `/api/associations/code/${associationCode}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const association = await associationResponse.json();
            this.setState({
                vehicle: {
                    associationId: association.id,
                    association: association,
                    registration: "",
                    variantId: association.variantId,
                    variant: association.variant,
                    leftHandDrive: false
                }
            }, () => {
                this.populatePaints(association.variantId);
            });
        }
        else this.setState({ loading: false });
    }

    async populateVechicle(id) {
        const vehicleResponse = await fetch(
            `/api/vehicles/${id}`, {
            headers: { 'Authorization': `Bearer ${this.state.token}` }
        });
        const contentType = vehicleResponse.headers.get("content-type")
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const vehicle = await vehicleResponse.json();
            this.setState({
                vehicle: vehicle,
                loading: false
            }, () => {
                this.populatePaints(vehicle.variantId);
            });
        }
        else {
            this.setState({ loading: false });
            try {
                console.log(vehicleResponse.text());
            }
            catch(err) {
                console.log(err);
            }
        }
    }
}
