import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import 'bootswatch/dist/yeti/bootstrap.min.css';

import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

ReactGA.initialize("UA-159334528-1");

const history = createBrowserHistory();
history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
});

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Router basename={baseUrl} history={history}>
    <App />
  </Router>,
  rootElement);


