import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { List } from './components/List';
import { VehicleEdit } from './components/VehicleEdit';

import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import ReactGA from "react-ga";

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/list/:associationCode' component={List} />
                <Route path='/vehicle/:id' component={VehicleEdit} />
                <Route path='/edit/:id' component={VehicleEdit} />                
                <Route path='/add/:associationCode' component={VehicleEdit} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
