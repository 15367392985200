import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

const status = require('http-status');

export class ResponseMessage {

    show(response) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div>
                        <h1 style={{ textAlign: 'center' }}>Something went wrong</h1>
                        <p style={{ textAlign: 'center' }}>{response.status} {status[response.status]}</p>
                        <div className="row">
                            <button className="btn btn-outline-primary mx-auto" onClick={onClose}>Continue</button>
                        </div>                             
                    </div>
                );
            }
        });
    }
}

const responseMessage = new ResponseMessage();

export default responseMessage;
