import React, { Component, Fragment } from 'react';

export class VehiclePaint extends Component {
    static displayName = VehiclePaint.name;

    render() {
        return (
            <Fragment>
                <svg width="15" height="10" >
                    <rect width="15" height="10" style={{
                        fill: this.props.paint.hex,
                        strokeWidth: "1",
                        stroke: "black"
                    }}></rect>
                </svg>
                <span className="ml-2">{this.props.paint.name} {this.props.paint.code}</span>
            </Fragment>
        );
    }
}
