import React, { Component } from 'react';
import './Footer.css';

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <footer className="footer fixed-bottom bg-light border-top pl-3 pb-1 text-muted text-small">
                <div className="row"><div className="col">carchive</div></div>
            </footer>
        );
    }
}
